.wrapper {
    margin-top: 30px;
    margin: 30px auto 0px auto;
    width: 80%;
}

p.news-text, p.news-date {
    text-align: left;
}

p.news-date {
    margin-top: 20px;
    font-weight: bold;
}

h1 {
    margin-bottom: 65px;
}

