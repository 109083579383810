.client-mapping-select .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #EBB000;
  border: none;/* Change default border color */
}

.client-mapping-select .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #EBB000;
  border: none;/* Change default border color */
}

.client-mapping-select .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #EBB000; 
  border: none;/* Change default border color */
}

