.delete-button{
  background: none;
	color: grey;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.delete-button:hover{
  color: #F91616;
}