@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.landing-container {
    display: flex; 
    flex-direction: column;
    padding-bottom: 20em;
    background-color: black;
    height: 100vh; 
    text-align: center; 
    color: white; 
    border-top: solid #FFC32C;
    font-size: 18px;
    padding-top: 10em;
}

.landing-container h2 {
    color: white;
    font-size: 18px;
}

.landing-container span {
    color: #FFC32C;
    text-align: center;
    font-size: 20px;
}

.get-keys{
    padding: 1em;
}

.landing-container img {
    width: 250px;
    margin-bottom: 1em;
}

/* Example Media Query */
@media (max-width: 600px) {
    .landing-container {
        font-size: 16px;
    }

    .landing-container h2 {
        font-size: 16px;
    }

    .landing-container img {
        width: 200px;
    }
}
