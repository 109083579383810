.componentContainer {
    display: flex;
    justify-content: center;
    margin-top: 10;
    /* flex-direction: column; */
}
.processedContainer {
    display: flex;
    justify-content: center;
    margin-top: 10;
    flex-direction: column;
}
.buttonGroup {
    display: flex;
    flex-direction: row;
    width: 30%;
    justify-content: space-between;
}
.container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    width: 80%;
}
.container > p {
    font-size: 1rem;
}
.container > em {
    font-size: .8rem;
}
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}